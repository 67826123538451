import React from "react";

import Paper from "@material-ui/core/Paper";

export default function Navigation(props) {

  return (
    <div>
      <br></br>
      <br></br>
      <Paper>
        Navigation
        <br></br>
        <p>{props.navigationTarget}</p>
        <br></br>
        
      </Paper>
    </div>
  );
}
