const NavigationConditionsEnum = {
  SUCCESS: "success",
  FAILURE: "failure_generic",
  TARGET_NOT_SPECIFIED: "target_not_specified",
};

const SearchConditionsEnum = {
  SUCCESS: "success",
  FAILURE: "failure_generic",
  ROUTE_NOT_FOUND: "failure_route_not_found",
  TICKET_NOT_AVAILABLE: "failure_ticket_not_available",

  SOURCE_NOT_SPECIFIED: "search_source_not_specified",
  SOURCE_INVALID: "search_source_invalid",
  SOURCE_AMBIGUOUS: "search_source_ambiguous",
  SOURCE_AMBIGUOUS_BAILOUT: "search_source_ambiguous_bailout",

  DESTINATION_NOT_SPECIFIED: "search_destination_not_specified",
  DESTINATION_INVALID: "search_destination_invalid",
  DESTINATION_AMBIGUOUS: "search_destination_ambiguous",
  DESTINATION_AMBIGUOUS_BAILOUT: "search_destination_ambiguous_bailout",

  SAME_SOURCE_DESTINATION: "search_source_destination_same",

  ONWARD_DATE_NOT_SPECIFIED: "search_onward_date_not_specified",
  ONWARD_DATE_INVALID: "search_onward_date_invalid",
  RETURN_DATE_NOT_SPECIFIED: "search_return_date_not_specified",
  RETURN_DATE_INVALID: "search_return_date_invalid",

  TRIP_TYPE_NOT_SPECIFIED: "search_trip_type_not_specified",

  PASSENGER_COUNT_NOT_SPECIFIED: "search_passenger_number_not_specified",
  PASSENGER_COUNT_INVALID: "search_passenger_number_invalid",

  FILTERS_APPLIED: "filters_applied_success",
  FILTERS_FAILURE: "filters_applied_failure",
  FILTERS_INVALID: "filters_applied_invalid",
  FILTERS_REMOVE_SUCCESS: "filters_removed_success",
  FILTERS_REMOVE_FAILURE: "filters_removed_failure",
  FILTERS_REMOVE_INVALID: "filters_removed_invalid",

  SORT_APPLIED: "sort_applied_success",
  SORT_FAILURE: "sort_applied_failure",
  SORT_INVALID: "sort_applied_invalid",
};

export { NavigationConditionsEnum, SearchConditionsEnum };
