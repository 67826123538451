import SlangTravelAssistant from "@slanglabs/slang-travel-assistant";
import { useEffect, useState } from "react";
import SearchTab from "./search";
import Navigation from "./navigation";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { NavigationConditionsEnum, SearchConditionsEnum } from "./constants";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function Main() {
  const [currentTab, setCurrentTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [appStateConditionList, setAppStateConditionList] = useState([]);
  const [utterance, setUtterance] = useState("");

  const [currentNavigationAppCondition, setCurrentNavigationAppCondition] =
    useState(NavigationConditionsEnum.SUCCESS);

  const [currentSearchAppCondition, setCurrentSearchAppCondition] = useState(
    SearchConditionsEnum.SUCCESS
  );

  const [navigationTarget, setNavigationTarget] = useState(null);

  const [currentSearchInfo, setCurrentSearchInfo] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
    if (newValue === 1) {
      SlangTravelAssistant.setAppDefaultUserJourney(
        SlangTravelAssistant.AssistantUserJourneys.NAVIGATION
      );
      setAppStateConditionList(NavigationConditionsEnum)
    }
    if (newValue === 0) {
      SlangTravelAssistant.setAppDefaultUserJourney(
        SlangTravelAssistant.AssistantUserJourneys.SEARCH
      );
      setAppStateConditionList(SearchConditionsEnum)
    }
  };

  const configureSlang = async () => {
    console.log(SlangTravelAssistant.VERSION);
    await SlangTravelAssistant.init({
      assistantID: "f6ca41bb9e094120b2f35cbc94ce9cb5",
      apiKey: "ec89d2c7fabe43d992565a78a90b70b6",
      requestedLocales: ["en-IN", "hi-IN"],
      appProperties: {
        app_name: "slang travel assistant",
      },
      configOverrides: {
        SLANG_INFER_SERVER_HOST: "infer-stage",
        SLANG_ASR_SERVER_HOST: "speech-stage",
        SLANG_TTS_SERVER_HOST: "tts-stage",
        SLANG_ANALYTICS_SERVER_HOST: "analytics-stage",
      },
    });
    SlangTravelAssistant.setAppDefaultSubDomain(
      SlangTravelAssistant.AssistantSubdomains.FLIGHT
    );

    // Trigger Positioning API Usage
    // SlangTravelAssistant.ui.setTriggerPosition({
    //   // rootElement: document.getElementById("slang-trigger"),
    //   position: SlangTravelAssistant.ui.TriggerPosition.CENTER, // Position snapping point
    //   canDrag: true, // allow dragging the trigger (only available for mobile right now)
    //   // alwaysVisible: true, // set visibility
    // });

    // Trigger Size API Usage
    // SlangTravelAssistant.ui.setTriggerSize(16, 10);

    // Brand Color API Usage
    // SlangTravelAssistant.ui.setBrandColor("#0051B7");

    // UI Theme API Usage
    // SlangTravelAssistant.ui.setUITheme(
    //   SlangTravelAssistant.ui.UITheme.DARK
    // );

    // Lifecycle observer usage example
    // SlangTravelAssistant.setLifecycleObserver({
    //   onAssistantInvoked: () => {
    //     console.log("onAssistantInvoked called");
    //   },
    //   onAssistantClosed: (isCancelled) => {
    //     console.log(
    //       "onAssistantClosed called \n",
    //       "Called by user? ",
    //       isCancelled
    //     );
    //   },
    //   onAssistantInitSuccess: () => {
    //     console.log("onAssistantInitSuccess called");
    //   },
    //   onAssistantInitFailure: () => {
    //     console.log("onAssistantInitFailure called");
    //   },
    //   onUtteranceDetected: (utterance) => {
    //     console.log(`onUtteranceDetected called with utterance "${utterance}"`);
    //   },
    //   onAssistantLocaleChanged: (changedLocale, oldLocale) => {
    //     console.log(`Locale changed from ${oldLocale} to ${changedLocale}`);
    //   },
    // });
    // SlangTravelAssistant.ui.setTriggerImageResource("/micImage.png");
    // SlangTravelAssistant.ui.disableSurfaceLocaleSelection(true);
    // SlangTravelAssistant.ui.setTypeface(`'Shizuru', cursive`);
    // SlangTravelAssistant.ui.setSecondaryTextColor("#00FF00");
    // SlangTravelAssistant.ui.setTextColor("#FF00A0");
    // SlangTravelAssistant.ui.disableSurfaceMuteButton(true);
    // SlangTravelAssistant.ui.disableSurfaceHelpButton(true);
    // SlangTravelAssistant.ui.setOnboardingInfoTitle({
    //   "en-IN": "Finding flights is now much easier",
    // });
    // SlangTravelAssistant.ui.setOnboardingInfoDescription({
    //   "en-IN": "You can now use voice to search for your flights with Go-MMT.",
    // });
    // SlangTravelAssistant.ui.setOnboardingPrimaryTitle({
    //   "en-IN": "You can now speak to your Go-MMT App",
    // });
    // SlangTravelAssistant.ui.setOnboardingPrimaryDescription({
    //   "en-IN": "Click here to start using voice",
    // });
    // SlangTravelAssistant.ui.setOnboardingInfoTitleSize(100);
    // SlangTravelAssistant.ui.setOnboardingInfoDescriptionSize(50);
    // SlangTravelAssistant.ui.setOnboardingTextColor("#FF0000");
    // SlangTravelAssistant.ui.setOnboardingAccentColor("#00FF00");
    // SlangTravelAssistant.ui.disableOnboardingLocaleSelection(true);
    // SlangTravelAssistant.ui.setSurfaceStyle(SlangTravelAssistant.ui.SurfaceStyle.CARD);
    // SlangTravelAssistant.ui.setSurfaceHintStyle(SlangTravelAssistant.ui.SurfaceHintStyle.LIST);
    
    SlangTravelAssistant.setLifecycleObserver({
      onUtteranceDetected: (utterance) => {
        setUtterance(utterance);
      }
    })
    // SlangTravelAssistant.ui.setTriggerStyle(SlangTravelAssistant.ui.TriggerStyle.GLOBAL_EXTENDED, {
    //   extendedTriggerTitle: {
    //     "en-IN": "You can search with me",
    //   }
    // });
    // Call show api after all customization APIs
    SlangTravelAssistant.ui.show();
  };
  
  useEffect(() => {
    configureSlang();
  }, []);

  const handleChangeAppStateCondition = () => {
    setOpen(true);

    return new Promise((resolve, reject) => {
      const conditionButtons = [
        ...document.getElementsByClassName("conditionButton"),
      ];
      const handleButtonClick = (event) => {
        setOpen(false);
        resolve(event.currentTarget.name);
        conditionButtons.forEach((btn) =>
          btn.removeEventListener("click", handleButtonClick)
        );
      };
      conditionButtons.forEach((btn) =>
        btn.addEventListener("click", handleButtonClick)
      );
    });
  };

  useEffect(() => {
    SlangTravelAssistant.setAction({
      onSearch: async (searchInfo, searchUserJourney) => {
        setAppStateConditionList(SearchConditionsEnum)
        handleChangeTab(null, 0);
        console.log(searchInfo, searchUserJourney);
        setCurrentSearchInfo(searchInfo);
        const searchAppCondition = await handleChangeAppStateCondition();
        switch (searchAppCondition) {
          case SearchConditionsEnum.SUCCESS: {
            searchUserJourney.setSuccess();
            break;
          }
          case SearchConditionsEnum.FAILURE: {
            searchUserJourney.setFailure();
            break;
          }

          case SearchConditionsEnum.ROUTE_NOT_FOUND: {
            searchUserJourney.setRouteNotFound();
            break;
          }

          case SearchConditionsEnum.TICKET_NOT_AVAILABLE: {
            searchUserJourney.setTicketNotAvailable();
            break;
          }

          case SearchConditionsEnum.SOURCE_NOT_SPECIFIED: {
            searchUserJourney.setNeedSource();
            break;
          }

          case SearchConditionsEnum.SOURCE_INVALID: {
            searchUserJourney.setSourceInvalid();
            break;
          }

          case SearchConditionsEnum.SOURCE_AMBIGUOUS: {
            searchUserJourney.setSourceAmbiguous();
            break;
          }

          case SearchConditionsEnum.SOURCE_AMBIGUOUS_BAILOUT: {
            searchUserJourney.setSourceSelectOnScreen();
            break;
          }

          case SearchConditionsEnum.DESTINATION_NOT_SPECIFIED: {
            searchUserJourney.setNeedDestination();
            break;
          }

          case SearchConditionsEnum.DESTINATION_INVALID: {
            searchUserJourney.setDestinationInvalid();
            break;
          }

          case SearchConditionsEnum.DESTINATION_AMBIGUOUS: {
            searchUserJourney.setDestinationAmbiguous();
            break;
          }

          case SearchConditionsEnum.DESTINATION_AMBIGUOUS_BAILOUT: {
            searchUserJourney.setDestinationSelectOnScreen();
            break;
          }

          case SearchConditionsEnum.ONWARD_DATE_NOT_SPECIFIED: {
            searchUserJourney.setNeedOnwardDate();
            break;
          }

          case SearchConditionsEnum.ONWARD_DATE_INVALID: {
            searchUserJourney.setOnwardDateInvalid();
            break;
          }

          case SearchConditionsEnum.RETURN_DATE_NOT_SPECIFIED: {
            searchUserJourney.setNeedReturnDate();
            break;
          }

          case SearchConditionsEnum.RETURN_DATE_INVALID: {
            searchUserJourney.setReturnDateInvalid();
            break;
          }

          case SearchConditionsEnum.TRIP_TYPE_NOT_SPECIFIED: {
            searchUserJourney.setNeedTripType();
            break;
          }

          case SearchConditionsEnum.SAME_SOURCE_DESTINATION: {
            searchUserJourney.setSameSourceDestination();
            break;
          }

          case SearchConditionsEnum.FILTERS_APPLIED: {
            searchUserJourney.setFiltersSuccess();
            break;
          }

          case SearchConditionsEnum.FILTERS_FAILURE: {
            searchUserJourney.setFiltersFailed();
            break;
          }

          case SearchConditionsEnum.FILTERS_INVALID: {
            searchUserJourney.setFiltersInvalid();
            break;
          }

          case SearchConditionsEnum.FILTERS_REMOVE_SUCCESS: {
            searchUserJourney.setFiltersRemoved();
            break;
          }

          case SearchConditionsEnum.FILTERS_REMOVE_FAILURE: {
            searchUserJourney.setFiltersRemoveFailed();
            break;
          }

          case SearchConditionsEnum.FILTERS_REMOVE_INVALID: {
            searchUserJourney.setFiltersRemoveInvalid();
            break;
          }

          case SearchConditionsEnum.SORT_APPLIED: {
            searchUserJourney.setSortSuccess();
            break;
          }

          case SearchConditionsEnum.SORT_FAILURE: {
            searchUserJourney.setSortFailure();
            break;
          }

          case SearchConditionsEnum.SORT_INVALID: {
            searchUserJourney.setSortInvalid();
            break;
          }

          default: {
            searchUserJourney.setSuccess();
          }
        }

        return searchUserJourney.AppStates.SEARCH_RESULTS;
      },
      onNavigation: async (navigationInfo, navigationUserJourney) => {
        setAppStateConditionList(NavigationConditionsEnum)
        console.log(navigationInfo, navigationUserJourney);

        setNavigationTarget(navigationInfo.target);
        handleChangeTab(null, 1);
        const navigationAppCondition = await handleChangeAppStateCondition();
        switch (navigationAppCondition) {
          case NavigationConditionsEnum.SUCCESS: {
            navigationUserJourney.setNavigationSuccess();
            break;
          }
          case NavigationConditionsEnum.FAILURE: {
            navigationUserJourney.setNavigationFailure();
            break;
          }
          case NavigationConditionsEnum.TARGET_NOT_SPECIFIED: {
            navigationUserJourney.setNeedTarget();
            break;
          }
          default: {
            navigationUserJourney.setNavigationSuccess();
          }
        }

        return navigationUserJourney.AppState.NAVIGATION;
      },
    });
  });

  const handleNonVoiceRouteSearch = (source, destination) => {
    SlangTravelAssistant.trackCustomEvent("travel_search", { source, destination })
  }

  return (
    <div className="App">
      <AppBar position="static">
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          aria-label="simple tabs example"
        >
          <Tab label="Search" />
          <Tab label="Navigation" />
        </Tabs>
      </AppBar>
      <TabPanel value={currentTab} index={0}>
        <SearchTab
          searchAppConditionState={{
            currentSearchAppCondition,
            setCurrentSearchAppCondition,
          }}
          currentSearchInfo={currentSearchInfo}
          utterance={utterance}
          handleNonVoiceRouteSearch={handleNonVoiceRouteSearch}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Navigation
          navigationTarget={navigationTarget}
          navigationAppConditionState={{
            currentNavigationAppCondition,
            setCurrentNavigationAppCondition,
          }}
          utterance={utterance}
        />
      </TabPanel>
      <Dialog open={open}>
            <DialogTitle>
              <Typography variant="button">AppState Conditions</Typography>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {Object.keys(appStateConditionList).map((condition, key) => (
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    key={key}
                    className="conditionButton"
                    name={appStateConditionList[condition]}
                    style={{ marginTop: "1rem" }}
                  >
                    {condition}
                  </Button>
                ))}
              </div>
            </DialogContent>
          </Dialog>
    </div>
  );
}

export default Main;
