import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ReactJson from "react-json-view";

import { Button, TextField } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Search(props) {
  const classes = useStyles();
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");

  const handleChangeSource = (event) => {
    setSource(event.target.value);
  }

  const handleChangeDestination = (event) => {
    setDestination(event.target.value);
  }

  const handleNonVoiceSearch = () => {
    if(source.trim() !== "" && destination.trim() !== "") {
      props.handleNonVoiceRouteSearch(source, destination);
    }
  }

  useEffect(() => {
    if (props.currentSearchInfo) {
      const source = props.currentSearchInfo.source;
      const destination = props.currentSearchInfo.destination;

      const sourceString = Object.values(source).filter(item => {
        if (item === null) return false;

        if( typeof item === "string") {
          return item.trim() !== "";
        }

        if( item instanceof Array ) {
          return item.length > 0;
        }
      }).join(", ")

      const destinationString = Object.values(destination).filter(item => {
        if (item === null) return false;

        if( typeof item === "string") {
          return item.trim() !== "";
        }

        if( item instanceof Array ) {
          return item.length > 0;
        }
      }).join(", ")

      setSource(sourceString);
      setDestination(destinationString);
    }
  }, [props.currentSearchInfo])

  return (
    <div>
      <Card variant="outlined">
        <CardContent>
          <TextField label="Utterance" fullWidth value={props.utterance} variant="outlined" size="small" style={{marginBottom: "1rem"}} />
          {props.currentSearchInfo ? (
            <ReactJson
              src={props.currentSearchInfo}
              collapsed={true}
              theme="hopscotch"
            />
          ) : (
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              No SearchInfo yet. Try Saying something.
            </Typography>
          )}
        </CardContent>
      </Card>
      <div style={{display: "flex", alignItems: "center", marginTop: "1rem"}}>
        <TextField label="Source" value={source} onChange={handleChangeSource} fullWidth variant="outlined" size="small" />
        <TextField label="Destination" value={destination} onChange={handleChangeDestination} fullWidth variant="outlined" size="small" style={{marginLeft: "1rem"}} />
        <div id="slang-trigger"></div>
      </div>
      <Button type="button" variant="contained" color="primary" style={{marginTop: "1rem"}} onClick={handleNonVoiceSearch}>Search</Button>
    </div>
  );
}
